/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: Caveat;
        src: url("./fonts/Caveat/Caveat-VariableFont_wght.ttf");
    }
}

/*
CF3F36
F58236
*/
:root{
    --westlandRed: #CF3F36;
}

html {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.card-panel{
    padding: 16px;
    transition: -webkit-box-shadow .25s;
    transition: box-shadow .25s, -webkit-box-shadow .25s;
    min-width: 16vw;
    max-width: 20vw;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
}

@media only screen and (min-width: 1920px) {
    .card-panel {
        min-width: 14vw;
        max-width: 20vw;
    }
}

.tooltip-override{
    border-radius: 0.75rem !important;
    font-size: .775rem !important;
    line-height: 1.75rem !important;
    background: rgba(27,27,27,.5)  !important;
}

.tableSize{
    max-height: 60vh;
}

body {
    background-color: #FCFCFC;
}

.main-container {
    background-color: white;
    min-height: auto !important;
    overflow: auto;
}


.expandedRow {
    font-size: 1.05rem !important;
    line-height: 1.75rem !important;
    font-weight: bold !important;
}

.notesDisplay{
    max-height: 60px !important;
    height: 60px !important;
}

.subRows{
    text-overflow: ellipsis;
    max-height: 1.6em !important; /* (Number of lines you want visible) * (line-height) */
    font-size: .80rem !important;
    line-height: .80rem !important;
}

.subRowIndicators {
    font-size: 1.20rem !important;
    line-height: 1.20rem !important;
}

.rowHideBorder{
    border-bottom: 0 !important;
}

.expandedHeader {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    height: 0 !important;
    max-height: 0 !important;
}

.glFilterColor {
    background-color: #fafafa !important;
}

.westlandButtonColor:focus {
    background-color: #F58236 !important;
}

.westlandRedText  {
    color: #CF3F36 !important;
}

.searchBox{
    width: 30%;
    border: 0 !important;
}

.periodicHeight{
    height: 500px;
}

.notFoundFont {
    font-size: 20rem;
    line-height: 1;
}

.animation-pulse-custom{
    animation-name: pulse;
    animation-duration: 1.6s;
    animation-iteration-count: 2;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
}
@keyframes pulse {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
}

.container-corner{
    color: black;
    margin-left: -35px;
    margin-top: -40px;

}
.container-corner:hover{
    transition: all .4s ease-in-out 0s;

    color: var(--westlandRed);
}

.review-table-borders{
    border-bottom: 1px solid black !important;
}

.h-75vh{
    height: 75%;
}

.h-50vh{
    height: 75%;
}

.w-50vw{
    width: 75%;
}

.w-38vw{
    width: 38vw;
}

.spacer{
    aspect-ratio: 1/3;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.review-table{
    flex: 85;
}
.review-math{
    flex: 15;
}

.review-notes{
    flex: 75;
}

.review-buttons{
    flex: 25;
}

.one {
    background-image: url("imgs/Invoice-amico.png");
    flex-basis:40.00%;
}

.two {
    background-color: #FEC8D8;
    flex-basis:60.00%;
}

.invoiceBlob{
    background-image: url("imgs/Invoice-amico.svg");
    background-repeat: no-repeat;
    background-position: bottom right 20%;
}
.blob{
    background-image: url("imgs/blob.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom right 120%;
}
@media (max-width: 1600px){
    .blob{
        background-position: bottom right 110%;
    }
    .searchBox{
        width: 40%;
    }
}

@media (max-width: 1260px){
    .blob{
        background-position: bottom right 105%;
    }
    .searchBox{
        width: 50%;
    }
}
